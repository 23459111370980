<template>
  <div class="clear" v-if="eventos != undefined">
    <div class="clear" v-for="evento in eventos">
      <p class="text-left my-1">
        <span :style="'color: '+evento.cor+'; fill: '+evento.cor">
          <i class="btn-icon-left" v-i:duo#record#20></i>
          <span class="fs-11pt f-lato fw-700 f-lato">{{evento.setor}}</span>
        </span>
        <span class="ms-4 ps-2 my-0 fs-10pt fw-400 f-lato" v-c:P>
          <span class="fw-700">Tema: </span>
          {{evento.tema}}
        </span>
        <span class="ms-4 fw-700">Início: </span>
        {{evento.dataInicioF}}
        <span class="fw-700 ms-2">Fim: </span>
        {{evento.dataFinalF}}
      </p >
      <p class="ms-4 ps-2 my-0 fs-13pt fw-300 f-lato" v-c:P>
        <span class="fw-700 fs-10pt me-1">Evento: </span>
        {{evento.descricao}}
      </p>
      <div class="mt-2">
        <div class="left ms-4 me-2 ps-2 mt-1 mb-1 fs-10pt fw-400 f-lato" v-c:P>
          <span class="fw-700">Responsáveis: </span>
        </div>
        <div class="left mb-2 me-2 mt-0 pt-0" v-for="user in evento.pessoas" v-c:P>
          <v-avatar size="38">
            <img :src="user.urlFoto" alt="">
          </v-avatar>
          <span class="ms-1 fs-10pt f-lato fw-300">{{user.nome}}</span>
        </div>
      </div>
      <div class="clear"></div>
      <hr class="clear mt-2">
    </div>
  </div>
</template>

<script>
//import { rdb, snapshotValToArray } from '@/firebase.js'
import { eventBus } from "@/main.js";
import { rdb, snapshotValToArray } from '@/firebasedb.js'

export default {
  name: 'itemevento',
  props: [ "eventos" ],
  data(){
    return {
    }
  },
  computed: {
  },
  created() {

  },
  mounted() {
    var self = this;

  },
  methods: {


  }
}

</script>

<style scoped lang="scss">

</style>
