<template>
  <div class="">
    <calendario :route="route" :key="refresh"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import moment from 'moment';
import { eventBus } from "@/main.js";
//import itemevento from "./itemevento.vue";
import calendario from "./calendario";

export default {
  name: 'routercalendario',
  components: { calendario },
  computed: {
  },
  data () {
    return {
      route: "",
      refresh: 0,
    }
  },
  watch: {
    $route(to, from) {
      console.log("routechange");
      var self = this;
      self.build();
    }
  },
  created: function() {
    var self = this;
    this.log("Created");
    /*
    eventBus.$on("initCalendar", function(route) {
      console.log("initCalendar",route);
      self.route = route;
      self.refresh++;
    });
*/

  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods: {

    build() {
      var self = this;
      self.route = this.$route.params.id;
      self.refresh++;
      self.$forceUpdate();
    }
  }
}
</script>

<style scoped>

</style>
